import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from "./../../elements/Button";
import InputField from "./../../elements/Input";
import MasterService from "../../../service/MasterService";
import CommonService from "../../../service/CommonService";
import Select from "react-select";
import ModalPopup from "../../elements/Popup";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import CheckBox from "./../../elements/CheckBox";
import ViewIFDetailsHistory from "./ViewIFDetailsHistory";
import Noimage from '../../../webroot/images/noimage.svg';
import secureStorage from '../../../config/encrypt';
import ViewImage from "./ViewImage";
import { NumericFormat as NumberFormat } from "react-number-format";

class InventoryFundingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basicDetails: props.basic_details || {},
            dealerIdHash: props.dealer_id_hash || '',
            loggedInuser: props.user_loggedin || 0,
            role_access: props.role_access || [],
            max:999999999999,
            ifSelectedOption: {},
            ifCategoryOptions: [],
            ifCategoryList: [],
            ifCategorySubOptions: {},

            ifHistoryDetailsPopup: false,

            ifDetailFormData: {},
            errors: {},
            loading: false,
            difDetail: [],
            img_url:"",
            show_image_modal: false
        };
    }

    componentDidMount = () => {
        this.getIFCategoryListAll();
        this.getIFCategoryDetails(this.state.dealerIdHash)
        this.getDIFDetail(this.state.dealerIdHash)
    };



    componentWillReceiveProps(nextProps) {
        this.setState({
            basicDetails: nextProps.basic_details,
            dealerIdHash: nextProps.dealer_id_hash,
            loggedInuser: nextProps.user_loggedin,
            role_access: nextProps.role_access || [],
        });
    }

    getDIFDetail = (dealerIdHash) => {
        this.setState({ loading: true });
        MasterService.post(`/dealer/inventory-funding/get_dif_info`, { dealer_id: dealerIdHash }).then((response) => {
            if (response && response.data && response.data.status === 200) {
                let difDetail = response.data.data;
                this.setState({ difDetail: difDetail });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                toast.error(response.data.message);
            }
        });
    };

    getIFCategoryListAll = () => {
        this.setState({ loading: true });
        MasterService.post(`/dealer/inventory-funding/get_if_category_list_menu`, {}).then((response) => {
            if (response && response.data && response.data.status === 200) {
                let categoryList = response.data.data.categoryList;
                let categoryOptions = response.data.data.categoryOptions;
                this.setState({ ifCategoryOptions: categoryOptions });
                this.setState({ ifCategoryList: categoryList });
                this.getIFCategoryDetails(this.state.dealerIdHash)
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                toast.error(response.data.message);
            }
        });
    };

    saveIfCategoryDetails = (params) => {
        // this.state.loading = true;//need to remove
        this.setState({ loading: true });
        let updated_by = 0;
        let created_by = 0;
        let dealer_id = '';
        let priority_dealer = 0;

        let loginResponse = secureStorage.getItem('loginUserInfo');
        let loginuserid = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.user_id;
        if (this.state.loggedInuser && this.state.dealerIdHash) {
            updated_by = loginuserid
            created_by = loginuserid
            dealer_id = this.state.dealerIdHash;
        }

        if (this.state.ifDetailFormData && this.state.ifDetailFormData.priority_dealer) {
            priority_dealer = this.state.ifDetailFormData.priority_dealer
        }

        let category_name = ''
        if (this.state.ifSelectedOption && this.state.ifSelectedOption.label) {
            category_name = this.state.ifSelectedOption.label
        }
        params = { ...params,category_name,is_dealer_subscribe_for_dif : this.state.ifDetailFormData.is_dealer_subscribe_for_dif, updated_by: updated_by, created_by: created_by, dealer_id: dealer_id, priority_dealer: priority_dealer }
        if (this.validForm()) {
            MasterService.post('/dealer/inventory-funding/save_if_category_details', params)
            .then((response) => {
                if (response && response.data && response.data.status === 200) {
                    this.setState({ loading: false })
                    toast.success(response.data.message)
                } else {
                    this.setState({ loading: false })
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                this.setState({ loading: false })
                toast.error(error);
            })
        } else {
            this.setState({ loading: false })
        }
    }

    validForm = () => {
        let fields = this.state.ifDetailFormData;
         let formIsValid = true;
         let errors = { ...this.state.errors };
        if (!fields["approved_limit"]) {
            errors['approved_limit'] =  this.props.t('dealerDetails.Inventory_Funding_Details.enter_drawing_power');
            formIsValid = false;
        }
        if (!fields["cashback"]) {
            errors['cashback'] =  this.props.t('dealerDetails.Inventory_Funding_Details.enter_cashback');
            formIsValid = false;
        }
        if (!fields["if_category_config_id"]) {
            errors['if_category_config_id'] =  this.props.t('dealerDetails.Inventory_Funding_Details.Select_Category');
            formIsValid = false;
        }
        if (!fields["facility_tenure"]) {
            errors['facility_tenure'] =  this.props.t('dealerDetails.Inventory_Funding_Details.enter_facility_tenure');
            formIsValid = false;
        }
        if (!fields["sanction_limit"]) {
            errors['sanction_limit'] =  this.props.t('dealerDetails.Inventory_Funding_Details.enter_sanction_limit');
            formIsValid = false;
        }
        if (!fields["per_car_limit"]) {
            errors['per_car_limit'] =  this.props.t('dealerDetails.Inventory_Funding_Details.enter_per_car_limit');
            formIsValid = false;
        }

        if (fields["approved_limit"] && fields["sanction_limit"]) {
            if (Number(fields["approved_limit"]) > Number(fields["sanction_limit"])) {
                errors['approved_limit'] = this.props.t('dealerDetails.Inventory_Funding_Details.dpltsl');
                formIsValid = false;
            } else {
                delete errors['approved_limit']
            }
        }

        if (fields["approved_limit"] && fields["per_car_limit"]) {
            if (Number(fields["approved_limit"]) <= Number(fields["per_car_limit"])) {
                errors['per_car_limit'] = this.props.t('dealerDetails.Inventory_Funding_Details.pclstdp');
                formIsValid = false;
            } else {
                delete errors['per_car_limit']
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    getIFCategoryDetails = (dealerIdHash) => {
        let self = this
        this.setState({ loading: true })
        MasterService.post('/dealer/inventory-funding/get_if_category_details', { dealer_id: dealerIdHash })
            .then((response) => {
                if (response && response.data && response.data.status) {
                    let responseData = response.data.data;

                     let if_category_config_id = 0
                     let value = self.state.ifCategoryOptions.filter(({ id }) => id === responseData.if_category_config_id)[0]
                    if(value && value.id){

                        if_category_config_id = value.id
                    }
                    let formData = {
                        if_category_config_id,
                        sanction_limit: responseData.sanction_limit,
                        approved_limit: responseData.approved_limit,
                        per_car_limit: responseData.per_car_limit,
                        facility_tenure: responseData.facility_tenure,
                        cashback: responseData.cashback,
                        priority_dealer: responseData.priority_dealer,
                        is_dealer_subscribe_for_dif:responseData.is_dealer_subscribe_for_dif ? responseData.is_dealer_subscribe_for_dif : 0,
                    };



                    let [selectData] = this.state.ifCategoryList.filter(item => item.id === responseData.if_category_config_id)
                    if (selectData) {
                        let selectedOption = { id: selectData.id, label: selectData.category_name };
                        this.setState({ ifSelectedOption: selectedOption })
                        this.getCategorySubOptions(selectedOption);
                    }





                    this.setState({ ifDetailFormData: formData })
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false })
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                this.setState({ loading: false })
                toast.error(error)
            })
    }

    __numberWithCommas = (num) => {
        return CommonService.__numberWithCommaSeperator(num);
      };

    getCategorySubOptions = (selectedData) => {
        const interestedKeys = {
            provision_fee: { label: 'Provision Fee', type: '%' },
            interest_fee: { label: 'Interest Fee', type: '%' },
            renewal_fee: { label: 'Renewal Fee', type: '%' },
            admin_fee: { label: 'Admin Fee', type: '%' },
            loan_tenure: { label: 'Loan Tenure', type: 'Days' },
            renewal_time: { label: 'Renewal Time', type: 'Days' },
            penalty_interest: { label: 'Penalty Interest', type: '%' },
            penalty_fee: { label: 'Penalty Fee', type: '' },
            ltv: { label: 'LTV', type: '%' },
          };
        let categorySubOptions = [];
        if (this.state.ifCategoryList && this.state.ifCategoryList.length) {
            for (const data of this.state.ifCategoryList) {
                if (selectedData.id === data.id) {
                    for (const [key, value] of Object.entries(data)) {
                        if (interestedKeys[key] && value !== null && value !== undefined) {
                            if(key == 'admin_fee'){
                                let valueData = data.admin_fee_type == 1 ? `${value} ${interestedKeys[key].type}` : `${value}`
                                categorySubOptions.push({
                                    id: key,
                                    label: `${interestedKeys[key].label}`,
                                    value: `${valueData}`,
                                });
                            }else if(key == 'penalty_fee'){
                                categorySubOptions.push({
                                    id: key,
                                    label: `${interestedKeys[key].label}`,
                                    value: `${this.__numberWithCommas(value)} ${interestedKeys[key].type}`,
                                });
                            }else{
                                categorySubOptions.push({
                                    id: key,
                                    label: `${interestedKeys[key].label}`,
                                    value: `${value} ${interestedKeys[key].type}`,
                                });
                            }
                        }else{
                            if (key == 'ltv') {
                              categorySubOptions.push({
                                id: key,
                                label: `${interestedKeys[key].label}`,
                                value: value == null ? '-' : `${value} ${interestedKeys[key].type}`,
                              });
                            }
                        }
                    }
                }
            }
        }
        this.setState({ ifCategorySubOptions: categorySubOptions });
    };

    handleCategoryChange = (data, name) => {
        let tempFromData = { ...this.state.ifDetailFormData };
        const errors = this.state.errors;
        tempFromData['if_category_config_id'] = data.id;
        delete errors['if_category_config_id'];

        this.setState({ ifSelectedOption: data });
        this.setState({ ifDetailFormData: tempFromData })
        this.getCategorySubOptions(data);
    };

    handleSubmitForm = (event, data) => {
        event.preventDefault();
        this.saveIfCategoryDetails(data);
    }

    handleInputChange = (event, name) => {
        let formData = { ...this.state.ifDetailFormData };
        const errors = this.state.errors;
        let value = event.target ? event.target.value : event.value;
        if(name === 'cashback'){
            const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/)
            if(validated){
                formData[name] = value;
            } 
        } else if (['sanction_limit', 'approved_limit', 'per_car_limit'].includes(name)) {
            const validated = value.match(/^(?!0(\.0+)?$)\d*(\.\d{0,2})?$/);
            formData[name] = validated ? value : '';
        } else {
            formData[name] = value;
        }
        delete errors[name];
        this.setState({ ifDetailFormData: formData })
    }

    handleChecked = (event, name) => {
        let formData = { ...this.state.ifDetailFormData };
        formData[name] = event.target.checked ? 1 : 0;
        this.setState({ ifDetailFormData: formData })
    }

    closeIfHistoryDetailsModel = () => {
        this.setState({ ifHistoryDetailsPopup: false });
    };

    handleIfDetailsHistory = () => {
        this.setState({ ifHistoryDetailsPopup: true })
    }

    handleDIF = (isDIF,dealer_id) => {
        this.setState({ loading: true });
        let dif_data = this.state.ifDetailFormData
        let isDIFActivated = isDIF == '1' ? '0' : '1';
        if(dealer_id > 0 && dif_data && dif_data.approved_limit && dif_data.sanction_limit && dif_data.if_category_config_id){
            let obj = {
                if_category_config_id:dif_data.if_category_config_id,
                is_dealer_subscribe_for_dif : isDIFActivated,
                dealer_id
            }
            MasterService.post('/dealer/dealer/update_dif', obj)
            .then(response => {
                if(response.data.status == 200){
                    let if_details = {...this.state.ifDetailFormData}
                     if_details.is_dealer_subscribe_for_dif = response.data.data.is_dealer_subscribe_for_dif
                    this.setState({
                        ifDetailFormData:if_details
                    });
                }else{
                     toast.error(response.data.message);
                }
            })
            .catch(error => console.log(error))
        }else{
            let if_details = {...this.state.ifDetailFormData}
            if_details.is_dealer_subscribe_for_dif = isDIFActivated
            this.setState({
                ifDetailFormData:if_details
            });
        }
        this.setState({ loading: false });
     }
    humanize = (str) => {
        var i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        let final_str = frags.join(' ').replace(' Url', '');
        if (final_str == 'Photo Of Banner') {
          final_str = 'Showroom Photo with Banner';
        } else if (final_str == 'Dealer Selfie') {
          final_str = 'Selfie Photo Owner';
        } else if (final_str == 'Bpkb On Hand') {
          final_str = 'Stock with BPKB Photo';
        } else if (final_str == 'Dealer Stock') {
          final_str = 'Dealer Stock/Inventory Photo';
        }
         return final_str
      }

    handleImageClick = (img_url) => {
        if (img_url && img_url != "") {
            this.setState({ img_url })
            this.showImageModal()
        }
    }

    closeImageModal = () => {
        this.setState({ show_image_modal: false });
    };

    showImageModal = () => {
        this.setState({ show_image_modal: true });
    };

    render() {
    // let { errors ,ifCategoryList, difDetail ,role_access } = this.state; //need to remove
       let { errors, difDetail ,role_access,basicDetails } = this.state; 
        return (
            <div>
                <h1>{this.props.t("dealerDetails.Inventory_Funding_Details.Inventory_Funding")}
                     <span className="f12">
                        { (basicDetails.gcd_code) ? <span> ({basicDetails.organization} - {basicDetails.gcd_code})</span> : '' }
                     </span>
                     <span className="f12">
                        {
                            (basicDetails.vendor_id) ? <span> Vendor ID - {basicDetails.vendor_id}</span> : ''
                        }
                    </span>
                </h1>
                <form
                    className={this.state.loading ? "text-left os-animation animated fadeInDown loading" : "text-left os-animation animated fadeInDown"}
                    data-os-animation="fadeInDown"
                    data-os-animation-delay="0s"
                    method="post"
                    id="basicdetail_form"
                    onSubmit={(e) => { this.handleSubmitForm(e, this.state.ifDetailFormData) }}
                >
                    <div className="card">
                        <div className="card-heading">
                            <h2 className="card-title">{this.props.t("dealerDetails.Inventory_Funding_Details.Inventory_Funding_Service")}
                            { role_access.includes("inventory_funding_activate") &&
                                <label className="switch-btn btn-link" style={{"fontSize": "16px"}}>                                                    
                                    <input className="switch-btn"  name={"active"} type="checkbox" 
                                    onChange={() => {
                                        this.handleDIF(this.state.ifDetailFormData.is_dealer_subscribe_for_dif,this.state.basicDetails.id)
                                    }}
                                    checked={(this.state.ifDetailFormData.is_dealer_subscribe_for_dif == '1') ? true : false} />
                                    <div className="slider round"></div>
                                    <span className="switch-label"></span>
                                    {this.state.ifDetailFormData.is_dealer_subscribe_for_dif == '1' ? this.props.t('dealerDetails.Inventory_Funding_Details.Activated') : this.props.t('dealerDetails.Inventory_Funding_Details.Not_Activated')}
                                </label> 
                            }
                            </h2>
                        </div>
                        { this.state.ifDetailFormData.is_dealer_subscribe_for_dif == '1' ? <>
                        <div className="card-body">
                            <div className="row">
                                <div className="pull-left col-sm-4">

                                    <label>{this.props.t("dealerDetails.Inventory_Funding_Details.Select_Category")}</label>
                                    <Select
                                        components={{ IndicatorSeparator:() => null }}
                                        id="if_category_config_id"
                                        options={this.state.ifCategoryOptions}
                                        onChange={(data) => {
                                            this.handleCategoryChange(data, "if_category_config_id");
                                        }}
                                        name="if_category_config_id"
                                        placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.Select_Category")}
                                        value={this.state.ifCategoryOptions.filter(({ id }) => id === this.state.ifDetailFormData.if_category_config_id) || 0}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ id }) => id}
                                    />
                                    {
                                       (errors && errors.if_category_config_id) ? <span className="error show">{errors.if_category_config_id}</span> : ''
                                    }
                                </div>
                                <div className="pull-right">
                                    <br />
                                    <Button
                                        btnClass="btn btn-secondary mrg-r30 "
                                        title={this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.View_History")}
                                        type="button"
                                        onClick={(e) => {this.handleIfDetailsHistory(e)}}
                                    />

                                </div>

                            </div>

                            <div className="row" style={{"marginBottom": "17px"}}>
                                    {this.state.ifCategorySubOptions &&
                                        this.state.ifCategorySubOptions.length > 0 &&
                                        this.state.ifCategorySubOptions.map((item, idx) => {
                                            return (
                                                <div className="col-sm-1">
                                                <span>
                                                    <span> {item.value} </span><br></br>
                                                    <span> {item.label} </span>
                                                </span>
                                                </div>
                                            );
                                        })}
                            </div>

                            <div className="row mrg-b15">
                                <div className="col-sm-4">
                                    <label>{`${this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Sanction_Limit")} (Rp)`}<span className="required" style={{ color: 'red', marginLeft: "4px" }}>*</span></label>
                                    <NumberFormat
                                        className="form-control mrg-b5"
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        type="text"
                                        name="sanction_limit"
                                        id="sanction_limit"
                                        placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Sanction_Limit")}
                                        value={this.state.ifDetailFormData.sanction_limit ? this.state.ifDetailFormData.sanction_limit : ""}
                                        onValueChange={(e) => { this.handleInputChange(e, "sanction_limit") }}
                                    />
                                    {
                                       (errors && errors.sanction_limit) ? <span className="error show">{errors.sanction_limit}</span> : ''
                                    }
                                </div>
                                <div className="col-sm-4">
                                    <label>{`${this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Drawing_Power")} (Rp)`}<span className="required" style={{ color: 'red', marginLeft: "4px" }}>*</span></label>
                                    <NumberFormat
                                        className="form-control"
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        type="text"
                                        name="approved_limit"
                                        id="approved_limit"
                                        placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Drawing_Power")}
                                        value={this.state.ifDetailFormData.approved_limit ? this.state.ifDetailFormData.approved_limit : ""}
                                        onValueChange={(e) => { this.handleInputChange(e, "approved_limit") }}
                                    />
                                    {
                                       (errors && errors.approved_limit) ? <span className="error show">{errors.approved_limit}</span> : ''
                                    }
                                </div>
                                <div className="col-sm-4">
                                    <label>{`${this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Per_Car_Limit")} (Rp)`}<span className="required" style={{ color: 'red', marginLeft: "4px" }}>*</span></label>
                                    <NumberFormat
                                        className="form-control"
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        type="text"
                                        name="per_car_limit"
                                        id="per_car_limit"
                                        placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Per_Car_Limit")}
                                        value={this.state.ifDetailFormData.per_car_limit ? this.state.ifDetailFormData.per_car_limit : ""}
                                        onValueChange={(e) => { this.handleInputChange(e, "per_car_limit") }}
                                    />
                                    {
                                       (errors && errors.per_car_limit) ? <span className="error show">{errors.per_car_limit}</span> : ''
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <InputField
                                        type="number"
                                        min="0"
                                        max={this.state.max}
                                        name="facility_tenure"
                                        id="facility_tenure"
                                        placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Facility_Tenure")}
                                        value={this.state.ifDetailFormData.facility_tenure ? this.state.ifDetailFormData.facility_tenure : ""}
                                        label={`${this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Facility_Tenure")} (Months)`}
                                        onChange={(e) => { this.handleInputChange(e, "facility_tenure") }}
                                        required={true}
                                    />
                                    {
                                       (errors && errors.facility_tenure) ? <span className="error show" style={{"marginTop": "-13px","lineHeight": "1px"}}>{errors.facility_tenure}</span> : ''
                                    }
                                </div>
                                <div className="col-sm-4">
                                    <InputField
                                        type="number"
                                        min="0"
                                        max="100"
                                        name="cashback"
                                        id="cashback"
                                        placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Cashback")}
                                        value={this.state.ifDetailFormData.cashback ? this.state.ifDetailFormData.cashback : ""}
                                        label={`${this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Cashback")} (%)`}
                                        onChange={(e) => { this.handleInputChange(e, "cashback") }}
                                        required={true}
                                    />
                                    {
                                       (errors && errors.cashback) ? <span className="error show" style={{"marginTop": "-13px","lineHeight": "1px"}}>{errors.cashback}</span> : ''
                                    }
                                </div>
                                <div className="col-sm-4">
                                    <br />
                                    <CheckBox
                                        type="checkbox"
                                        name="priority_dealer"
                                        id="priority_dealer"
                                        value={this.state.ifDetailFormData.priority_dealer ? this.state.ifDetailFormData.priority_dealer : ""}
                                        checked={this.state.ifDetailFormData.priority_dealer ? true : false}
                                        label={this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.Priority_Dealer")}
                                        onChange={(e) => { this.handleChecked(e, 'priority_dealer') }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">
                                {" "}
                                <i className="ic-arrow_back"></i> {this.props.t("dealerDetails.Outlet_Details_master.Previous")}{" "}
                            </NavLink>
                            { role_access.includes("inventory_funding_details_save") &&
                                <Button
                                    btnClass="btn btn-primary mrg-r30"
                                    title={this.props.t("dealerDetails.Inventory_Funding_Details.Save_Details")}
                                    type="submit"
                                />
                            }
                            {/* {basicinfo && basicinfo.id && basicinfo.id > 0 ? (
                                <NavLink to={this.props.nextpage} className="btn btn-default">
                                    {this.props.t("dealerDetails.Outlet_Details_master.Next")} <i className="ic-arrow_forward"></i>
                                </NavLink>
                            ) : (
                                ""
                            )} */}
                        </div>
                        </>: <div className="inventory_inactive_funding">
                            <img src={Noimage} alt=""></img>
                            <span>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_Page.IF_NOT_ACTIVATED")}</span>
                            </div>}
                    </div>
                    {difDetail && Object.keys(difDetail).length > 0 &&
                        <div className="card">
                            <div className="card-heading">
                                <h2 className="card-title">{this.props.t("dealerDetails.Inventory_Funding_Details.Inventory_Funding_Detail")}</h2>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="pull-left col-sm-12 inventory_funding_detail">
                                        <h3>Basic Details</h3>
                                        <ul>
                                            <li>
                                                <span>Start Of Business</span>
                                                <label>{difDetail.start_of_business?difDetail.start_of_business:"-"}</label>
                                            </li>
                                            <li>
                                                <span>Average Units Sold per Month</span>
                                                <label>{difDetail.average_unit_sold_per_month?difDetail.average_unit_sold_per_month:"-"}</label>
                                            </li>
                                            <li>
                                                <span>Ownership Type</span>
                                                <label>{difDetail.ownership_type?this.humanize(difDetail.ownership_type):"-"}</label>
                                            </li>
                                            <li>
                                                <span>No. of UCF Transactions</span>
                                                <label>{difDetail.no_of_ucf_transaction?difDetail.no_of_ucf_transaction:"-"}</label>
                                            </li>
                                            <li>
                                                <span>BPKB Copy on Hand</span>
                                                <label>{difDetail.bpkb_copy_on_hand?difDetail.bpkb_copy_on_hand:"-"}</label>
                                            </li>
                                            <li>
                                                <span>Residential address dif from KTP</span>
                                                <label>{difDetail.residential_address_dif_from_ktp?"Yes":"No"}</label>
                                            </li>
                                            <li>
                                                <span>Residential Address</span>
                                                <label>{difDetail.residential_address?difDetail.residential_address:"-"}</label>
                                            </li>
                                            <li>
                                                <span>Pincode</span>
                                                <label>{difDetail.pincode?difDetail.pincode:"-"}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pull-left col-sm-12 inventory_funding_detail">
                                        <h3>Credit Line</h3>
                                        <ul >
                                            <li className="w-100">
                                                <span>Existing Credit Line</span>
                                                <label>{difDetail.existing_credit_line ? "Yes" : "No"}</label>
                                            </li>
                                           
                                            {difDetail.existing_credit_line == 1 &&
                                                <div className="w-100">
                                                    {difDetail.credit_lines && difDetail.credit_lines.length > 0 && difDetail.credit_lines.map((element, index) => (
                                                        <div className="funding-detail-with-img" key={index}>
                                                            <ul>
                                                                <li>
                                                                    <span>Financier Name</span>
                                                                    <label>{element.financier_name ? element.financier_name : "-"}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Interest Fee</span>
                                                                    <label>{element.interest_fee ? element.interest_fee : "-"}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Admin Fee</span>
                                                                    <label>{element.admin_fee ? element.admin_fee : "-"}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Provision Fee</span>
                                                                    <label>{element.provision_fee ? element.provision_fee : "-"}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Tenure</span>
                                                                    <label>{element.tenure ? element.tenure : "-"}</label>
                                                                </li>
                                                                {element.agreement_doc_url && element.agreement_doc_url.length > 0 &&
                                                                    <li>
                                                                        <span>Agreement DOC</span>
                                                                        {element.agreement_doc_url && element.agreement_doc_url.length > 0 && element.agreement_doc_url.map((img, img_ind) => (
                                                                            <div className="funding-img">
                                                                                {  img && img.includes('.pdf?') ? <a href={img} target="_blank" rel="noreferrer" download> <i className="ic-pdf-file icons-normal"></i>  View File </a> : <li onClick={()=>this.handleImageClick(img)}>{img && <img key={img_ind} src={img} alt="" />}</li> }
                                                                            </div>
                                                                        ))}
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </ul>
                                        {difDetail.documents && difDetail.documents["2"] && Object.keys(difDetail.documents["2"]).length > 0 && Object.keys(difDetail.documents["2"]).map((ele, index) => (
                                            <ul>
                                                <li className="w-100" key={index} >
                                                    <span>{this.humanize(ele)}</span>
                                                    <div className="funding-img">
                                                        {difDetail.documents["2"][ele] && difDetail.documents["2"][ele].length > 0 && difDetail.documents["2"][ele].map((img, img_ind) => (
                                                            <li onClick={()=>this.handleImageClick(img.doc_url)}>{img.doc_url && <img key={img_ind} src={img.doc_url} alt="" />}</li>
                                                        ))}
                                                     </div>
                                                </li>
                                            </ul>
                                        ))}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pull-left col-sm-12 inventory_funding_detail">
                                        <h3>Other Details</h3>
                                        {difDetail.documents && difDetail.documents["3"] && Object.keys(difDetail.documents["3"]).length > 0 && Object.keys(difDetail.documents["3"]).map((ele, index) => (
                                            <ul>
                                                <li className="w-100" key={index} >
                                                    <span>{this.humanize(ele)}</span>
                                                    <div className="funding-img">
                                                    {difDetail.documents["3"][ele] && difDetail.documents["3"][ele].length > 0 && difDetail.documents["3"][ele].map((img, img_ind) => (
                                                       <li onClick={()=>this.handleImageClick(img.doc_url)}>{img.doc_url && <img key={img_ind} src={img.doc_url} alt="" />}</li>
                                                    ))}
                                                     </div>
                                                </li>
                                            </ul>
                                        ))}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pull-left col-sm-12 inventory_funding_detail">
                                        <h3>Business Details</h3>
                                        <ul>
                                            <li>
                                                <span>Business License Type</span>
                                                <label>{difDetail.business_license_type?difDetail.business_license_type.toUpperCase():"-"}</label>
                                            </li>
                                        </ul>
                                        {difDetail.documents && difDetail.documents["4"] && Object.keys(difDetail.documents["4"]).length > 0 && Object.keys(difDetail.documents["4"]).map((ele, index) => (
                                            <ul>
                                                <li className="w-100" key={index} >
                                                    <span>{this.humanize(ele)}</span>
                                                    {difDetail.documents["4"][ele] && difDetail.documents["4"][ele].length > 0 && difDetail.documents["4"][ele].map((img, img_ind) => (
                                                      <div className="funding-img">
                                                       <li onClick={()=>this.handleImageClick(img.doc_url)}>{img.doc_url && <img key={img_ind} src={img.doc_url} alt="" />}</li>
                                                       </div>
                                                    ))}
                                                </li>
                                            </ul>
                                        ))}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pull-left col-sm-12 inventory_funding_detail">
                                        <h3>Upload Other Photos Detail</h3>
                                        {difDetail.documents && difDetail.documents["5"] && Object.keys(difDetail.documents["5"]).length > 0 && Object.keys(difDetail.documents["5"]).map((ele, index) => (
                                            <ul>
                                                <li className="w-100" key={index} >
                                                    <span>{this.humanize(ele)}</span>
                                                    <div className="funding-img">
                                                    {difDetail.documents["5"][ele] && difDetail.documents["5"][ele].length > 0 && difDetail.documents["5"][ele].map((img, img_ind) => (
                                                    
                                                      <li onClick={()=>this.handleImageClick(img.doc_url)}>{img.doc_url && <img key={img_ind} src={img.doc_url} alt="" />}</li>
                                                      
                                                    ))}
                                                    </div>
                                                
                                                </li>
                                            </ul>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </form>
                {this.state.ifHistoryDetailsPopup === false ? null : (
                    <ModalPopup
                        id="if_view_details_history"
                        className="modal ViewHistory data-table"
                        title={`${this.state.ifSelectedOption.label} - Category History`}
                        modalClose={this.closeIfHistoryDetailsModel}
                    >
                        <ViewIFDetailsHistory
                            dealerIdHash={this.state.dealerIdHash}
                            categoryName={this.state.ifSelectedOption.label}
                            formData={this.state.formData}
                        />

                    </ModalPopup>
                )}
                {this.state.show_image_modal == false ? null :
                    <ModalPopup id="show_image" className="modal show_image" modalClose={this.closeImageModal}>
                        <ViewImage modalClose={this.closeImageModal} image_url={this.state.img_url}/>
                    </ModalPopup>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};

export default withTranslation("dealers")(connect(mapStateToProps, mapDispatchToProps)(InventoryFundingDetails));
