import React, { Component } from 'react';
import SearchDealer from './search-dealer';
import { Redirect } from 'react-router-dom';
import SearchResult from './Manageresult';
import secureStorage from './../../../config/encrypt';
// import ModalPopup from '../../elements/Popup';//need to remove
import DateFormate from 'dateformat';
import { connect } from 'react-redux';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import { Reason_For_Inactivate, RoleId } from '../../../config/constant';
import { toast } from 'react-toastify';
import { getStatusByValue } from "../../common/Utils";
class ManageDealerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            pageloading:true,
            dealerListData: [],
            searchByList: [],
            dealerRelationship: [],            
            cityAllList: [],
            dcSkusList: [],
            role_access: [],
            dealerCountStatusDetail: {},
            page: 0,
            filterData: {
                status:'1'
            },
            redirect: false,
            mappingCsvData:[],
            dealerPremiumTypeList:[]
        };
        this.onScroll = this.onScroll.bind(this);
        this.getDealerList = this.getDealerList.bind(this);
        this.flag = true;
        this.csvLink = React.createRef();

    }
    updateReduxData = async () => {
        let role_access = []
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            role_access = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role_data && loginResponse.data.user_data.role_data.length > 0 ? loginResponse.data.user_data.role_data : []
            this.setState({ role_access })
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        await this.updateReduxData();
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        await this.getSearchByList();
        await this.getCityList();
        await this.getDealerSkus();
        await this.getDealerCountStatusDetail();
        await this.getLanguageList()

    }

    getLanguageList() {
        let self = this
        MasterService.getMasters(['dealer_premium_type'],
            { 'headers': { 'Authorization': self.props.auth_token } })
            .then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    self.setState({
                        dealerPremiumTypeList: response.data.data.dealer_premium_type,
                    }, () => {
                    })
                } else if (response.status == 401 && response.data.status == 401) {
                    self.setState({ redirect: true })
                }
            }).catch((error) => {
            });
    }

    afterLoginSetState = (loginResponse) => {
        // let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;//need to remove
        let role = loginResponse?.data?.user_data?.role || null;
        if (role != "admin") {
            this.setState({ redirect: '/' })
        }
    }
    handleOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        if(sname == 'relation_dealer'){
            filterData[sname] = (oValue.code != null) ? oValue.code : oValue.target.code;
        }else{
            filterData[sname] = (oValue.value != null) ? oValue.value : oValue.target.value;
        }
        this.setState({
            filterData
        });
    }
    handleCityOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.id) != null ? oValue.id : oValue.target.id;
        this.setState({
            filterData
        });
    }
    handleSkuOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.sku_short_name) != null ? oValue.sku_short_name : oValue.target.sku_short_name;
        this.setState({
            filterData
        });
    }
    handlePremiumChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.code) != null ? oValue.code : oValue.target.code;
        this.setState({
            filterData
        })
    }

    handleStatusClick = async (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname == 'active') {
            filterData['status'] = '1';
        } else if (sname == 'inactive') {
            filterData['status'] = '2';
        }else if (sname == 'incomplete') {
            filterData['status'] = '3';
        }else if (sname == 'pending') {
            filterData['status'] = '4';
        }else if (sname == 'blacklist') {
            filterData['status'] = '5';
        }   else if (sname == 'reject') {
            filterData['status'] = '6';
        }else if (sname == 'all') {
            filterData['status'] = '';
        }
        this.setState({ filterData });
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.page=1;
        // this.setState({ page: 1 });
        this.getDealerList();
        this.getDealerCountStatusDetail();
    }
    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page==0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
                    this.getDealerList();
                });
            }
            this.flag = false;
        }
    }

    getDealerList() {

        var thisObj = this;
        //this.setState({loading:true})
        MasterService.post('dealer/dealer/dealer-list', { 'page_no': this.state.page, ...this.state.filterData })
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({loading:false,pageloading:false})
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                dealerListData: thisObj.state.dealerListData.concat(response.data.data)
                            ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data});
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    }

    submitFilterForm = () => {

        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.page=1;
        // this.setState({ page: 1 });
        this.setState({pageloading:true})
        this.getDealerList();
        this.getDealerCountStatusDetail();
    }

    submitExportData = () => {
        console.log("submitExportData")
        this.setState({pageloading:true})
        this.getExportDataList();
    }

    getExportDataList() {

        var thisObj = this;
        let status = this.state.filterData.status
        //this.setState({loading:true})
        MasterService.post('dealer/dealer/get_export_data', { 'page_no': this.state.page, ...this.state.filterData })
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({ loading: false, pageloading: false })
                if (response.data.status == 200) {
                    let csv_object = []
                    if(status == '2'){
                        csv_object = response.data.data.map(elm => ({
                            "Dealer Status": elm.status ? getStatusByValue(elm.status):'Unknown',
                            "GCD Code": elm.gcd_code ? elm.gcd_code : "",
                            "Dealeship Name": elm.organization ? elm.organization : "",
                            "Dealership Email": elm.dealership_email ? elm.dealership_email : "",
                            "Dealer Contact Number": elm.dealership_contact ? elm.dealership_contact : "",
                            "Dealer Address": elm.address ? elm.address : "",
                            "Owner Name": elm.owner_name ? elm.owner_name : "",
                            "Date of Joining": elm.date_of_joining ? DateFormate(new Date(elm.date_of_joining), "yyyy-mm-dd") : "",
                            "Created Date": elm.created_date ? DateFormate(new Date(elm.created_date), "yyyy-mm-dd") : "",
                            "Outlet Name": elm.outlet_name ? elm.outlet_name : "",
                            "State": elm.statename ? elm.statename : "",
                            "City": elm.cityname ? elm.cityname : "",
                            "Location": elm.localityname ? elm.localityname : "",
                            "Onboarded By": elm.onboarded_by ? elm.onboarded_by : "Admin",
                            "UserRole": elm.user_role ? RoleId[elm.user_role] : "Admin",
                            "Relationship With dealer":elm.relationship_with_dealer ? elm.relationship_with_dealer : "",
                            "Dealer Type": elm.premium_code && thisObj.state.dealerPremiumTypeList.findIndex(({ code }) => code === elm.premium_code) > -1 ? thisObj.state.dealerPremiumTypeList.filter(({ code }) => code === elm.premium_code)[0].title : "",
                            "Reason for Inactive": elm.inactive_reason && elm.inactive_reason == 'others' ? (elm.inactive_reason_comment ? elm.inactive_reason_comment : "") : (Reason_For_Inactivate.findIndex(({ value }) => value === elm.inactive_reason) > -1 ? Reason_For_Inactivate.filter(({ value }) => value === elm.inactive_reason)[0].label : ""),
                            "KTP": elm.ktp_ids ? elm.ktp_ids : ""
                        }));
                    } else {
                        csv_object = response.data.data.map(elm => ({
                            "Dealer Status": elm.status ? getStatusByValue(elm.status):'Unknown',
                            "GCD Code": elm.gcd_code ? elm.gcd_code : "",
                            "Dealeship Name": elm.organization ? elm.organization : "",
                            "Dealership Email": elm.dealership_email ? elm.dealership_email : "",
                            "Dealer Contact Number": elm.dealership_contact ? elm.dealership_contact : "",
                            "Dealer Address": elm.address ? elm.address : "",
                            "Owner Name": elm.owner_name ? elm.owner_name : "",
                            "Date of Joining": elm.date_of_joining ? DateFormate(new Date(elm.date_of_joining), "yyyy-mm-dd") : "",
                            "Created Date": elm.created_date ? DateFormate(new Date(elm.created_date), "yyyy-mm-dd") : "",
                            "Outlet Name": elm.outlet_name ? elm.outlet_name : "",
                            "State": elm.statename ? elm.statename : "",
                            "City": elm.cityname ? elm.cityname : "",
                            "Location": elm.localityname ? elm.localityname : "",
                            "Onboarded By": elm.onboarded_by ? elm.onboarded_by : "Admin",
                            "UserRole": elm.user_role ? RoleId[elm.user_role] : "Admin",
                            "Dealer Type": elm.premium_code && thisObj.state.dealerPremiumTypeList.findIndex(({ code }) => code === elm.premium_code) > -1 ? thisObj.state.dealerPremiumTypeList.filter(({ code }) => code === elm.premium_code)[0].title : "",
                            "Relationship With dealer":elm.relationship_with_dealer ? elm.relationship_with_dealer : "",
                            "KTP": elm.ktp_ids ? elm.ktp_ids : ""
                        }));
                    }
                    thisObj.setState({ mappingCsvData: csv_object }, () => {
                        if (thisObj.csvLink.current && thisObj.csvLink.current.link) thisObj.csvLink.current.link.click();
                    })
                } else {
                    if(response.data.message){
                        toast.error(response.data.message)
                    }
                }
            })
            .catch(function (error) {
                console.log("error", error)
                thisObj.setState({ loading: false, pageloading: false })
            });
    }

    getSearchByList() {
        // var parentObj = this;
        // var thisObj = this;
        MasterService.get('dealer/dealer/search-by-list')
            .then((searchResult) => {
                this.flag = false;
                if (searchResult && searchResult.data && searchResult.data.status == 200 && searchResult.data.data) {
                    this.setState({
                        searchByList: searchResult.data.data.search_by_list,
                        dealerRelationship:searchResult.data.data.relation_with_dealer
                    })
                }
            })
            .catch(function (searchError) {
                console.error("searchError", searchError)
            });
    }
    getCityList() {
        var parentObj = this;
        
        var thisObj = this;
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                // console.log(response);return false;
                thisObj.flag = false;
                if (response.data.status == 200) {
                    parentObj.setState({
                        cityAllList: response.data.data.city
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getDealerSkus() {
        var parentObj = this;
        // const headers = {//need to remove unused variable
        //     'Content-Type': 'application/json',
        //     'Authorization': this.props.auth_token,
        //     'Accept-Language': 'en'
        // }
        var thisObj = this;
        MasterService.get('core/commonservice/master/?master[]=dealer_sku')
            .then(function (response) {
                // console.log(response);return false;
                thisObj.flag = false;
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        dcSkusList: response.data.data.dealer_sku || []
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getDealerCountStatusDetail() {
        var parentObj = this;
        // const headers = {//need to remove unused variable
        //     'Content-Type': 'application/json',
        //     'Authorization': this.props.auth_token,
        //     'Accept-Language': 'en'
        // }
        // var thisObj = this;//need to remove unused variable
        MasterService.post('dealer/dealer/count_dealer_status', { 'page_no': this.state.page, ...this.state.filterData })
            .then(function (response) {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        dealerCountStatusDetail: response.data.data || {}
                    },()=>{
                    })
                }
            })
            .catch(function (response) {
            });
    }
  
    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }

        let role_access = []
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            role_access = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role_data && loginResponse.data.user_data.role_data.length > 0 ? loginResponse.data.user_data.role_data : []
        }
        return (
            <div className={(this.state.pageloading) ? "container-fluid loading":"container-fluid"}>
                <div className="addStock">
                    <h1>{this.props.t('Listing.Dealer_Listing')}</h1>
                    <SearchDealer dcSkusList={this.state.dcSkusList} cityAllList={this.state.cityAllList} searchByList={this.state.searchByList} dealerRelationship={this.state.dealerRelationship} dealerPremiumTypeList={this.state.dealerPremiumTypeList} optionChange={this.handleOptionChange} optionCityChange={this.handleCityOptionChange} optionSkuChange={this.handleSkuOptionChange} optionPremiumChange={this.handlePremiumChange} onSubmitFilter={this.submitFilterForm}/>
                    <div className="clearfix">
                        <SearchResult role_access={role_access} pageloading={this.state.pageloading} loading={this.state.loading} dealerListData={this.state.dealerListData} statusClick={this.handleStatusClick} dealerCountStatusDetail={this.state.dealerCountStatusDetail} filterData={this.state.filterData} onExportData={this.submitExportData}/>
                        <CSVLink
                            asyncOnClick={true}
                            className="hidden"
                            ref={this.csvLink}
                            data={this.state.mappingCsvData ? this.state.mappingCsvData : []}
                            filename={`dealer-list-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                        >
                            {this.props.t('Listing.Search_Result.Export_Data')}
                        </CSVLink>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(ManageDealerList));
//export default connect(mapStateToProps, mapDispatchToProps)(ManageDealerList);
//export default ManageDealerList;
